import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import Home from '../views/login.vue';

Vue.use(VueRouter);
const ifAuthenticated = (to: any, from: any, next: any) => {
  if (localStorage.admin) {
    next();
    return;
  }
  next('/');
};

const ifNotAuthenticated = (to: any, from: any, next: any) => {
  if (!localStorage.admin) {
    next();
    return;
  }
  next('/admin');
};

const routes: RouteConfig[] = [
  {
    path: '/',
    component: Home,
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: '/admin',
    beforeEnter: ifAuthenticated,

    component: () => import('../views/layout.vue'),
    children: [
      // Users
      {
        path: 'home',
        component: () => import('../views/router.vue'),
        children: [
          // admins
          {
            path: '',
            component: () => import('../views/system/home.vue'),
          },
        ],
      },
      // Users
      {
        path: 'users',
        component: () => import('../views/router.vue'),
        children: [
          // admins
          {
            path: 'admins',
            component: () => import('../views/users/admins/index.vue'),
          },
          {
            path: 'admins/add',
            component: () => import('../views/users/admins/add.vue'),
          },
          {
            path: 'admins/edit/:id',
            component: () => import('../views/users/admins/add.vue'),
          },
        ],
      },
      // Users
      {
        path: 'system',
        component: () => import('../views/router.vue'),
        children: [
          // sections
          {
            path: 'sections',
            component: () => import('../views/system/sections/index.vue'),
          },
          {
            path: 'sections/add',
            component: () => import('../views/system/sections/add.vue'),
          },
          {
            path: 'sections/edit/:id',
            component: () => import('../views/system/sections/add.vue'),
          },
          // products
          {
            path: 'products',
            component: () => import('../views/system/products/index.vue'),
          },
          {
            path: 'products/add',
            component: () => import('../views/system/products/add.vue'),
          },
          {
            path: 'products/edit/:id',
            component: () => import('../views/system/products/add.vue'),
          },
          // says
          {
            path: 'says',
            component: () => import('../views/system/says/index.vue'),
          },
          {
            path: 'says/add',
            component: () => import('../views/system/says/add.vue'),
          },
          {
            path: 'says/edit/:id',
            component: () => import('../views/system/says/add.vue'),
          },
          // works
          {
            path: 'works',
            component: () => import('../views/system/works/index.vue'),
          },
          {
            path: 'works/add',
            component: () => import('../views/system/works/add.vue'),
          },
          {
            path: 'works/edit/:id',
            component: () => import('../views/system/works/add.vue'),
          },
          // clients
          {
            path: 'clients',
            component: () => import('../views/system/clients/index.vue'),
          },
          {
            path: 'clients/add',
            component: () => import('../views/system/clients/add.vue'),
          },
          {
            path: 'clients/edit/:id',
            component: () => import('../views/system/clients/add.vue'),
          },
          // pages
          {
            path: 'pages',
            component: () => import('../views/system/pages/index.vue'),
          },
          {
            path: 'pages/add',
            component: () => import('../views/system/pages/add.vue'),
          },
          {
            path: 'pages/edit/:id',
            component: () => import('../views/system/pages/add.vue'),
          },
          // slider
          {
            path: 'slider',
            component: () => import('../views/system/slider/index.vue'),
          },
          {
            path: 'slider/add',
            component: () => import('../views/system/slider/add.vue'),
          },
          {
            path: 'slider/edit/:id',
            component: () => import('../views/system/slider/add.vue'),
          },
          {
            path: 'contact',
            component: () => import('../views/system/contact/index.vue'),
          },
          {
            path: 'orders',
            component: () => import('../views/system/orders/index.vue'),
          },
          
        ],
      },
    ],
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
