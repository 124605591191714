import axios from 'axios';
// export const baseURL = 'http://localhost:4466';

export const baseURL = window.location.origin;
export const baseUploadURL = baseURL + '/uploads/';
const admin = localStorage.admin ? JSON.parse(localStorage.admin) : '';

const user = localStorage.user ? JSON.parse(localStorage.user) : '';
export const HTTP = axios.create({
  baseURL: `${baseURL}/api/`,
  headers: {
    Authorization: `Bearer ${admin?.token ? admin?.token : user?.token}`,
  },
});
